import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [{
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/home/Home')
  },{
    path: '/instructions',
    name: 'instructions',
    component: () => import(/* webpackChunkName: "instructions" */ '../views/instructions/instructions')
  },{
    path: '/panel',
    name: 'panel',
    component: () => import(/* webpackChunkName: "instructions" */ '../views/panel/panel')
  },{
    path: '/firmware',
    name: 'firmware',
    component: () => import(/* webpackChunkName: "instructions" */ '../views/firmware/firmware')
  },{
    path: '/theme',
    name: 'theme',
    component: () => import(/* webpackChunkName: "instructions" */ '../views/theme/theme')
  },{
    path: '/imageup',
    name: 'imageup',
    component: () => import(/* webpackChunkName: "instructions" */ '../views/imageup/imageup')
  },{
    path: '/video',
    name: 'video',
    component: () => import(/* webpackChunkName: "instructions" */ '../views/video/video')
  },{
    path: '/support',
    name: 'support',
    component: () => import(/* webpackChunkName: "instructions" */ '../views/support/support')
  },{
    path: '/setting',
    name: 'setting',
    component: () => import(/* webpackChunkName: "instructions" */ '../views/setting/setting')
  },{
    path: '/manualrow',
    name: 'manualrow',
    component: () => import(/* webpackChunkName: "instructions" */ '../views/manualrow/manualrow')
  },{
    path: '/kf',
    name: 'kf',
    component: () => import(/* webpackChunkName: "instructions" */ '../views/kf/kf')
  },{
    path: '/uploadlist',
    name: 'uploadlist',
    component: () => import(/* webpackChunkName: "uploadlist" */ '../views/uploadlist/uploadlist')
  }, {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login/Login'),
    beforeEnter(to, from, next) {
      const { isLogin } = sessionStorage;
      isLogin ? next({ name: 'Home'}):  next();
    }
  }, {
    path: '/qlogin/:token',
    name: 'Qlogin',
    component: () => import(/* webpackChunkName: "qlogin" */ '../views/qlogin/Qlogin'),
    beforeEnter(to, from, next) {
      const { isLogin } = sessionStorage;
      isLogin ? next({ name: 'panel'}):  next();
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from ,next) => {
  const { isLogin } = sessionStorage;
  const { name } = to;
  // const isLoginOrRegister = (name === "Qlogin" || name === "Register");
  const isLoginOrRegister = (name === "Qlogin" );
  (isLogin || isLoginOrRegister) ? next() : next({ name: 'Qlogin',params: { token: 'SXmini' }});
})

export default router
